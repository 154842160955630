export const share_img = require('@images/share_img.png')
export const share_img_black = require('@images/share_img_black.png')

export const like_on_img = require('@images/like_on_img.png')
export const like_off_img = require('@images/like_off_img.png')
export const like_off_img_gray = require('@images/like_off_img_gray.png')
export const like_artist_img = require('@images/like_artist_img.png')
export const like_track_img = require('@images/like_track_img.png')

export const kakao_login_btn_img = require('@images/kakao_login_btn_img.png')
export const facebook_login_btn_img = require('@images/facebook_login_btn_img.png')
export const apple_login_btn_img = require('@images/apple_login_btn_img.png')
export const google_login_btn_img = require('@images/google_login_btn_img.png')

export const burger_menu_img = require('@images/burger_menu_img.png')
export const search_btn_img = require('@images/search_btn_img.png')
export const search_btn_img_gray = require('@images/search_btn_img_gray.png')
export const burger_menu_img_black = require('@images/burger_menu_img_black.png')
export const search_btn_img_black = require('@images/search_btn_img_black.png')
export const search_outline_24px = require('@images/search_outline_24px.png')
export const x_btn_img = require('@images/x_btn_img.png')
export const x_btn_black_img = require('@images/x_btn_black_img.png')
export const x_btn_white_img = require('@images/x_btn_white_img.png')
export const x_btn_gray_img = require('@images/x_btn_gray_img.png')

export const plam_logo_img = require('@images/plam_logo_img.png')
export const plamin_logo_img = require('@images/plamin_logo_img.png')
export const plam_app_logo_img = require('@images/plam_app_logo_img.png')

export const albums_fill_24px = require('@images/albums_fill_24px.png')

export const circle_facebook_img = require('@images/circle_facebook_img.png')
export const circle_kakao_img = require('@images/circle_kakao_img.png')
export const circle_google_img = require('@images/circle_google_img.png')

export const default_album_img = require('@images/default_album_img.png')
export const default_profile_img = require('@images/default_profile_img.png')
export const default_music_img = require('@images/default_music_img.png')

export const clip_img = require('@images/clip_img.png')

export const Title_img = require('@images/title_img.png')
export const shadow_img = require('@images/contract_shadow.png')
export const btn_img = require('@images/contract_btn.png')

export const back_img = require('@images/streaming_shadow.png')

export const like_gray_img = require('@images/like_gray_img.png')

export const sns_weverse = require('@images/sns_weverse.png')
export const sns_youtube = require('@images/sns_youtube.png')
export const sns_twitter = require('@images/sns_twitter.png')
export const sns_instagram = require('@images/sns_instagram.png')
export const goback_img = require('@images/goback_img.png')
export const back_black = require('@images/back_black.png')

export const star_img = require('@images/star_img.png')
export const star_img_empty = require('@images/star_img_empty.png')
export const favorite_img_empty = require('@images/favorite_img_empty.png')
export const fan_score_img = require('@images/fan_score_img.png')
export const favorite_img = require('@images/favorite_img.png')

export const melon_follower_img = require('@images/melon_follower_img.png')
export const instagram_follower_img = require('@images/instagram_follower_img.png')
export const soundcloud_follower_img = require('@images/soundcloud_follower_img.png')
export const twitter_follower_img = require('@images/twitter_follower_img.png')
export const youtube_follower_img = require('@images/youtube_follower_img.png')
export const move_btn_img = require('@images/move_btn_img.png')
export const move_btn_bottom_img = require('@images/move_btn_bottom_img.png')
export const move_down_gray_img = require('@images/move_down_gray_img.png')
export const move_prev_btn_img = require('@images/move_prev_btn_img.png')
export const move_btn_img_white = require('@images/move_btn_img_white.png')
export const beyond_plam_img = require('@images/beyond_plam_img.png')
export const value_img = require('@images/value_img.png')
export const comment_img = require('@images/comment_img.png')
export const referral_img = require('@images/referral_img.png')

export const recycle_img_gray = require('@images/recycle_img_gray.png')
export const auth_artist_img = require('@images/auth_artist_img.png')
export const edit_img_black = require('@images/edit_img_black.png')

export const no_prev_page_img = require('@images/no_prev_page_img.png')
export const prev_page_img = require('@images/prev_page_img.png')
export const next_page_img = require('@images/next_page_img.png')
export const no_next_page_img = require('@images/no_next_page_img.png')

export const no_first_page_img = require('@images/no_first_page_img.png')
export const first_page_img = require('@images/first_page_img.png')
export const last_page_img = require('@images/last_page_img.png')
export const no_last_page_img = require('@images/no_last_page_img.png')

export const upload_photo_img = require('@images/upload_photo_img.png')
export const remove_item_img = require('@images/remove_item_img.png')

export const trash_img_red = require('@images/trash_img_red.png')

export const select_option_img_black = require('@images/select_option_img_black.png')
export const no_select_option_img = require('@images/no_select_option_img.png')
export const article_list_img = require('@images/article_list_img.png')
export const error_img = require('@images/error_img.png')
export const notice_img = require('@images/notice_img.png')
export const check_empty_img = require('@images/check_empty_img.png')
export const check_img_blue = require('@images/check_img_blue.png')
export const check_img = require('@images/check_img.png')
export const copy_img = require('@images/copy_img.png')
export const x_circle_black_img = require('@images/x_circle_black_img.png')
export const camera_img = require('@images/camera_img.png')
export const tooltip_head_img = require('@images/tooltip_head_img.png')
export const open_alarm_talk_img = require('@images/open_alarm_talk_img.png')
export const artist_login_btn = require('@images/artist_login_btn.png')

export const plam_mbti_main_gif = require('@images/mbti/plam_mbti_main_gif.gif')
export const plam_mbti_loading_gif = require('@images/mbti/plam_mbti_loading_gif.gif')
export const plam_mbti_kakao_img = require('@images/mbti/plam_mbti_kakao.jpg')
export const plam_mbti_banner_01 = require('@images/mbti/mbti_banner1.jpg')
export const plam_mbti_banner_02 = require('@images/mbti/mbti_banner2.jpg')

export const url_share_btn = require('@images/mbti/url_share_btn.png')
export const twitter_share_btn = require('@images/mbti/twitter_share_btn.png')
export const facebook_share_btn = require('@images/mbti/facebook_share_btn.png')
export const kakao_share_btn = require('@images/mbti/kakao_share_btn.png')

export const arrow_left_img = require('@images/arrow_left_img.png')
export const link_copy_img_gray = require('@images/link_copy_img_gray.png')
export const no_melon_card_img = require('@images/no_melon_card_img.png')
export const plamin_circle_logo_img = require('@images/plamin_circle_logo_img.png')

export const contact_banner = require('@images/contact_banner.png')
export const contact_banner2 = require('@images/contact_banner2.png')
export const home_btn_img_black = require('@images/home_btn_img_black.png')
export const arrow_right_white_img = require('@images/arrow_right_white_img.png')
export const arrow_right_outline_24px = require('@images/arrow_right_outline_24px.png')

export const download_qr = require('@images/download_qr.png')
export const playstore_logo = require('@images/playstore_logo.png')
export const apple_logo = require('@images/apple_logo.png')
export const point_img = require('@images/point_img.png')
export const play_img = require('@images/play_img.png')

export const app_icon = require('@images/app_icon.png')
export const download_preview_img = require('@images/download_preview_img.png')

export const alarm_icon = require('@images/alarm_icon.png')

export const thumbup_fill_24_off = require('@images/thumbup_fill_24_off.png')
export const thumbup_fill_24_on = require('@images/thumbup_fill_24_on.png')
export const comments_fill_24 = require('@images/comments_fill_24.png')
export const view_fill_24px = require('@images/view_fill_24px.png')
export const pen_fill_24 = require('@images/pen_fill_24.png')
export const white_pen_fill_24px = require('@images/white_pen_fill_24px.png')
export const star_fill_24 = require('@images/star_fill_24.png')
export const drop = require('@images/drop.png')
export const fluent_arrow_24 = require('@images/fluent_arrow_24.png')
