import qs from 'qs'

import { IS_DEV, DOMAIN_URL_LENGTH } from '@consts'
import SubdomainPage from './SubdomainPage'

const routeSubdomain = Component => {
  /* sub domain parsing */
  const { host } = window.location
  const { protocol } = window.location
  const parts = host.split('.')
  const query = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
    // 문자열 맨 앞의 ?를 생력
  })
  const subdomain =
    (parts.length > DOMAIN_URL_LENGTH
      && parts[0] !== 'link-test'
      && parts[0])
    || (IS_DEV && query?.subdomain)
    || null
  const isSubdomain = !!subdomain

  return isSubdomain ? SubdomainPage : Component
}

export default routeSubdomain
