import { Network } from '.'

export default class AlbumNetwork {
  constructor(network: Network) {
    this.network = network
  }

  async getAlbum(albumId) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/album/${albumId}`,
        'get',
      )
      const album = data && data['data'] && data['data']['album']
      return album || null
    }
    catch (error) {
      console.warn('AlbumNetwork getAlbum error', error)
    }
  }

  async getAlbums(params) {
    try {
      const { data } = await this.network._axiosApiAuth(`/album`, 'get', params)
      const albumList = data && data['data'] && data['data']['albumList']
      return albumList || []
    }
    catch (error) {
      console.warn('AblumNetwork getAlbums error', error)
    }
  }
}
