import { observable } from 'mobx'
import { STORAGE_URL } from '@consts'
import TrackModel from './TrackModel'

export default class InplamModel {
  @observable _id
  @observable uniqueName
  @observable name
  @observable subdomain
  @observable isInplamOpen
  @observable articleCount
  @observable lastArticleCreatedAt
  @observable firstSortKey
  @observable secondSortKey
  @observable thirdSortKey
  @observable fourthSortKey
  @observable artistId

  @observable imageOriginalPath
  @observable image64Path
  @observable image128Path
  @observable image512Path

  @observable isInplamFavorite
  @observable artistUserFanSpotPointInfo
  @observable fanSpotPointInfo

  @observable isReleasedNewTrack
  @observable trackInfo

  get imageUri() {
    return `${STORAGE_URL}${this.imageOriginalPath}`
  }
  get image512Uri() {
    return `${STORAGE_URL}${this.image512Path || this.imageOriginalPath}`
  }
  get image128Uri() {
    return `${STORAGE_URL}${this.image128Path || this.imageOriginalPath}`
  }
  get image64Uri() {
    return `${STORAGE_URL}${this.image64Path || this.imageOriginalPath}`
  }

  constructor(stores, props) {
    if (props) {
      this._id = props._id
      this.uniqueName = props.uniqueName
      this.name = props.name
      this.subdomain = props.subdomain
      this.isInplamOpen = props.isInplamOpen
      this.articleCount = props.articleCount
      this.lastArticleCreatedAt = props.lastArticleCreatedAt
      this.firstSortKey = props.firstSortKey
      this.secondSortKey = props.secondSortKey
      this.thirdSortKey = props.thirdSortKey
      this.fourthSortKey = props.fourthSortKey
      this.artistId = props.artistId

      this.imageOriginalPath = props.imageOriginalPath
      this.image64Path = props.image64Path
      this.image128Path = props.image128Path
      this.image512Path = props.image512Path

      this.isInplamFavorite = props.isInplamFavorite
      this.artistUserFanSpotPointInfo = props.artistUserFanSpotPointInfo
      this.fanSpotPointInfo = props.fanSpotPointInfo

      this.isReleasedNewTrack = props.isReleasedNewTrack
      this.trackInfo = new TrackModel(stores, props.trackInfo)
    }
  }
}
