import {
  ConfirmPopup,
  FanspotListBottomModal,
  OkCancelPopup,
} from '@components/organisms'
import { InPlamAddTemplate } from '@components/templates'
import { useStore } from '@utils/hooks'
import { observer, useAsObservableSource } from 'mobx-react'
import React, { useState, useEffect } from 'react'
import { colors } from '@colors/'
import FanspotCategoryBottomModal from '@components/organisms/Popup/FanspotCategoryBottomModal'

const InPlamAddPage = ({ location, match, history }) => {
  const { articleStore, artistStore, authStore, inplamStore } = useStore()

  const currentUser = authStore.currentUser || null
  const updateLike = authStore.updateLike || (() => {})

  const [isChange, setIsChange] = useState(false) // 수정 한게 있는지
  const [showLoginPopup, setShowLoginPopup] = useState(false) // 로그인 요구 팝업
  const [artistInfo, setArtistInfo] = useState(null) // 아티스트 정보
  const [isUploading, setIsUploading] = useState(false)

  const [isFanSpotModalOpen, setIsFanSpotModalOpen] = useState(false)

  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false)
  const [fanSpotCategoryList, setFanSpotCategoryList] = useState([])
  // const [selectedCategory, setSelectedCategory] = useState(null)

  // 게시글 파일 리스트
  const [fileList, setFileList] = useState(
    location.state
      && location.state.isUpdate
      && articleStore.articleDetail
      && articleStore.articleDetail.imageOriginalPathList
      ? articleStore.articleDetail.imageOriginalPathList
      : [],
  )

  // 게시글 카테고리
  const [selectedCategory, setSelectedCategory] = useState(
    location.state && location.state.isUpdate && articleStore.articleDetail
      ? {
        _id: articleStore.articleDetail.articleCategoryId,
        name: articleStore.articleDetail.articleCategoryName,
      }
      : null,
  )
  // 게시글 제목
  const [title, setTitle] = useState(
    location.state && location.state.isUpdate && articleStore.articleDetail
      ? articleStore.articleDetail.title
      : '',
  )

  // 게시글 내용
  const [content, setContent] = useState(
    location.state && location.state.isUpdate && articleStore.articleDetail
      ? articleStore.articleDetail.text
      : '',
  )

  // 익명 여부
  const [isAnonymous, setIsAnonymous] = useState(
    location.state && location.state.isUpdate && articleStore.articleDetail
      ? articleStore.articleDetail.isAnonymous
      : false,
  )

  const [usingPointValue, setUsingPointValue] = useState(
    currentUser?.currentPoint
      ? currentUser?.currentPoint < 10
        ? currentUser?.currentPoint
        : 10
      : 0,
  )

  const {
    isShowing: cancelShowing,
    toggle: toggleCancel,
  } = OkCancelPopup.usePopup()

  const {
    isShowing: memberShowing,
    toggle: memberToggle,
  } = OkCancelPopup.usePopup()
  const {
    isShowing: errorShowing,
    toggle: errorToggle,
  } = ConfirmPopup.usePopup()
  const {
    isShowing: pointErrorShowing,
    toggle: pointErrorToggle,
  } = ConfirmPopup.usePopup()

  // 로그인 체크
  const checkAuth = e => {
    if (!authStore.currentUser) {
      setShowLoginPopup(true)
      if (e) {
        e.preventDefault()
        e.stopPropagation()
      }
      return false
    }
  }

  const handleAnonymous = value => {
    if (!isChange) setIsChange(true)
    setIsAnonymous(value)
  }

  // 좋아요 누르고 바로 글 등록(수정)
  const handleMemberWrite = async () => {
    // await updateLike(
    //   'add',
    //   currentUser._id,
    //   match.params.dataObjectId,
    //   'artist',
    // )

    await inplamStore.addFavoritesInplam(match.params.dataObjectId)

    await _createArticle()
  }

  // 글 등록(수정) 함수
  const _createArticle = async () => {
    if (title === '' || content === '' || isUploading) return

    // 로그인 체크
    checkAuth()
    // 팬스팟 즐겨찾기 체크
    if (!articleStore?.inplamFavoritesInfo) {
      memberToggle()
      return
    }
    // // 좋아요 체크
    // if (authStore.likeInfo && !authStore.likeInfo.isLike) {
    //   likeToggle()
    //   return
    // }

    // 등록
    if (fileList.length > 9) {
      errorToggle()
      return
    }

    // 수정
    if (location.state && location.state.isUpdate) {
      if (!isChange) return

      const options = {
        imageOriginalPathList:
          fileList && fileList.length > 0 ? fileList : null,
        title,
        text: content,
        isAnonymous,
        // artistId: match.params.dataObjectId,
      }

      if (selectedCategory) {
        options.articleCategoryId = selectedCategory?._id
      }

      const created = await articleStore.updateArticle(
        articleStore.articleDetail._id,
        options,
      )
      if (!created) return
    }

    // 생성
    else {
      // 포인트 부족
      if (currentUser?.currentPoint < usingPointValue || !usingPointValue) {
        pointErrorToggle()
        return
      }

      const options = {
        imageOriginalPathList:
          fileList && fileList.length > 0 ? fileList : null,
        title,
        text: content,
        artistId: match.params.dataObjectId,
        isAnonymous,
        fanSpotPoint: usingPointValue,
      }

      if (selectedCategory) {
        options.articleCategoryId = selectedCategory?._id
      }

      const created = await articleStore.createArticle(options)
      if (!created) return
    }

    history.push(`/fanspot/${match.params.uniqueName}`)
  }

  // 수정 사항이 있을 경우, setIsChange
  useEffect(() => {
    if (
      location.state
      && location.state.isUpdate
      && articleStore.articleDetail
    ) {
      if (
        articleStore.articleDetail.title !== title
        || articleStore.articleDetail.text !== content
        || (articleStore.articleDetail.imageOriginalPathList
          && articleStore.articleDetail.imageOriginalPathList !== fileList)
        || isUploading
      ) {
        setIsChange(true)
      }
    }
  }, [location, title, content, fileList, isUploading])

  // 헤더에 사용될 아티스트 정보
  useEffect(() => {
    let res
    const fetchArtistInfo = async () => {
      res = await artistStore.fetchArtistDetailById(match.params.dataObjectId)
      setArtistInfo(res)
    }
    fetchArtistInfo()
  }, [match.params.dataObjectId])

  // 좋아요 체크
  useEffect(() => {
    if (authStore.currentUser && authStore.currentUser._id) {
      authStore.fetchLike(match.params.dataObjectId, authStore.currentUser._id)
    }
  }, [authStore.currentUser, match.params.dataObjectId])

  return (
    <>
      <InPlamAddTemplate
        currentUser={currentUser}
        fileList={fileList}
        setFileList={setFileList}
        title={title}
        setTitle={setTitle}
        content={content}
        setContent={setContent}
        usingPointValue={usingPointValue}
        setUsingPointValue={setUsingPointValue}
        _createArticle={_createArticle}
        toggleCancel={toggleCancel}
        artistInfo={artistInfo}
        isUpdate={(location.state && location.state.isUpdate) || false}
        isChange={isChange}
        showLoginPopup={showLoginPopup}
        setShowLoginPopup={setShowLoginPopup}
        checkAuth={checkAuth}
        isUploading={isUploading}
        setIsUploading={setIsUploading}
        isAnonymous={isAnonymous}
        handleAnonymous={handleAnonymous}
        setIsFanSpotModalOpen={setIsFanSpotModalOpen}
        setIsCategoryModalOpen={setIsCategoryModalOpen}
        isFavorite={!!articleStore?.inplamFavoritesInfo}
        selectedCategory={selectedCategory}
      />
      <OkCancelPopup.View
        isShowing={cancelShowing}
        toggle={toggleCancel}
        onOk={() => history.push(`/fanspot/${match.params.uniqueName}`)}
        title={
          location.state && location.state.isUpdate
            ? `수정 중인 글이 취소되며,\n 수정된 내용은 저장되지 않습니다`
            : `작성 중인 글이 취소되며,\n 작성된 글은 저장되지 않습니다`
        }
      />
      <OkCancelPopup.View
        isShowing={memberShowing}
        toggle={memberToggle}
        title={'팬스팟 멤버가 되어야 글을 등록할 수 있습니다'}
        okText="멤버 등록"
        okColor={colors.primary}
        onOk={handleMemberWrite}
      />
      <ConfirmPopup.View
        isShowing={errorShowing}
        toggle={errorToggle}
        title={'한 게시글 당 이미지 첨부는 9장까지 가능합니다'}
        confirmColor={colors.primary}
      />
      <ConfirmPopup.View
        isShowing={pointErrorShowing}
        toggle={pointErrorToggle}
        title={'보유 포인트가 부족합니다'}
        confirmColor={colors.primary}
      />

      <FanspotListBottomModal
        isModalOpen={isFanSpotModalOpen}
        setIsFanSpotModalOpen={setIsFanSpotModalOpen}
        history={history}
      />

      <FanspotCategoryBottomModal
        isModalOpen={isCategoryModalOpen}
        setIsCategoryModalOpen={setIsCategoryModalOpen}
        history={history}
        fetchCategory={articleStore.fetchArticleCategoryList}
        fanSpotCategoryList={fanSpotCategoryList}
        setFanSpotCategoryList={setFanSpotCategoryList}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
      />
    </>
  )
}

export default observer(InPlamAddPage)
