import { Network } from '.'

export default class ArticleNetwork {
  constructor(network: Network) {
    this.network = network
  }

  getArticles = async params => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/articles`,
        'get',
        params,
      )
      return (data && data['data']) || null
    }
    catch (error) {
      console.warn('ArticleNetwork getArticles error', error)
    }
  }

  getArticle = async (articleId, sortBy, listType) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/articles/${articleId}?sortBy=${sortBy}&listType=${listType}`,
        'get',
      )
      return (data && data['data']) || null
    }
    catch (error) {
      console.warn('ArticleNetwork getArticle error', error)
      return error.response && error.response.data
    }
  }

  postArticles = async params => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/articles`,
        'post',
        params,
      )
      return (data && data['data']) || null
    }
    catch (error) {
      console.warn('ArticleNetwork postArticles error', error)
    }
  }

  putArticles = async (articleId, params) => {
    if (!articleId) return
    try {
      const { data } = await this.network._axiosApiAuth(
        `/articles/${articleId}`,
        'put',
        params,
      )
      return (data && data['data']) || null
    }
    catch (error) {
      console.warn('ArticleNetwork putArticles error', error)
    }
  }

  deleteArticles = async (articleId, params) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/articles/${articleId}`,
        'delete',
        params,
      )
      return (data && data['data']) || null
    }
    catch (error) {
      console.warn('ArticleNetwork deleteArticles error', error)
    }
  }

  postArticlesLikes = async (articleId, userId) => {
    try {
      const {
        data,
      } = await this.network._axiosApiAuth(
        `/articles/${articleId}/likes`,
        'post',
        { userId },
      )
      return (data && data['data']) || null
    }
    catch (error) {
      console.warn('ArticleNetwork postArticlesLikes error', error)
    }
  }

  deleteArticlesLikes = async (articleId, userId) => {
    try {
      const {
        data,
      } = await this.network._axiosApiAuth(
        `/articles/${articleId}/likes`,
        'delete',
        { userId },
      )
      return (data && data['data']) || null
    }
    catch (error) {
      console.warn('ArticleNetwork deleteArticlesLikes error', error)
    }
  }

  postArticlesReports = async (articleId, params) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/articles/${articleId}/reports`,
        'post',
        params,
      )
      return (data && data) || null
    }
    catch (error) {
      console.warn('ArticleNetwork postArticlesReports error', error)
    }
  }

  getArticleCategory = async () => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/articles/categories`,
        'get',
      )
      return (data && data['data']) || null
    }
    catch (error) {
      console.warn('ArticleNetwork getArticleCategory error', error)
      return error.response && error.response.data
    }
  }
}
