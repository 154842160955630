import { Caption1, Flex } from '@components/atoms'
import React from 'react'
import { STORAGE_URL } from '@consts'
import styled, { css } from 'styled-components'
import { favorite_img, star_fill_24 } from '@images/index'
import { convertToPricingComma } from '@utils/format'

const ellipsisStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '1',
  WebkitBoxOrient: 'vertical',
  wordWrap: 'nowrap',
  width: '100%',
}

const InplamArtistScoreListItem = ({ item, onClick = () => {} }) => {
  return (
    <Container style={{ height: '48px' }} align="center">
      <ArtistName align="center">
        <Box
          isOpen={item?.isInplamOpen}
          isFavorite={item?.isInplamFavorite}
          onClick={onClick}
        >
          <img
            alt={item?._id}
            src={STORAGE_URL + item?.image64Path}
            width={24}
            height={24}
            style={{ borderRadius: '50%', marginRight: '6px' }}
          />
          <Caption1
            style={{ ...ellipsisStyle, wordBreak: 'break-all' }}
            align="left"
          >
            {item?.name || item?.artistName || ''}
          </Caption1>
          {
item?.isInplamFavorite ? (
  <img
    src={star_fill_24}
    width={16}
    height={16}
    style={{ marginLeft: '2px' }}
    alt="favorite"
  />
) : (
  <></>
)
          }
        </Box>
      </ArtistName>

      <Score align="center" justify="center">
        <Caption1 align="center">
          {
            !item?.fanSpotPointInfo?.point
              ? '-'
              : item?.fanSpotPointInfo?.point > 999999999
                ? `${convertToPricingComma(999999999)}+`
                : convertToPricingComma(item?.fanSpotPointInfo?.point)
          }
        </Caption1>
      </Score>
      <MyScore align="center" justify="center">
        <Caption1 align="center">
          {
            !item?.artistUserFanSpotPointInfo?.point
              ? '-'
              : item?.artistUserFanSpotPointInfo?.point > 999999999
                ? `${convertToPricingComma(999999999)}+`
                : convertToPricingComma(item?.artistUserFanSpotPointInfo?.point)
          }
        </Caption1>
      </MyScore>
    </Container>
  )
}

export default InplamArtistScoreListItem

const Container = styled(Flex)`
  height: 48px;
  background-color: #fff;
  border-bottom: solid 1px #f4f4f4;
  margin-left: -20px;
  width: calc(100% + 40px);
  max-width: 440px;
  flex: 1;
`

const ArtistName = styled(Flex)`
  width: 176px;
  height: 48px;
  padding-left: 22px;
  flex: 1;
`
const Score = styled(Flex)`
  width: 92px;
  height: 48px;
  /* flex: 0.25; */
`
const MyScore = styled(Flex)`
  width: 92px;
  height: 48px;
  /* flex: 0.25; */
`

const Box = styled(Flex)`
  /* gap: 6px; */
  width: fit-content;
  height: 32px;
  padding: 4px 8px 4px 4px;
  background-color: #242424;
  /* border: solid 1px #242424; */
  color: #ffffff;

  border-radius: 24px;
  box-sizing: border-box;
  cursor: pointer;

  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);

  justify-content: center;
  align-items: center;

  ${({ isOpen, isFavorite }) =>
    isFavorite
      ? css`
          background-color: #242424;
          /* border: solid 1px #242424; */
          color: #ffffff;
        `
      : isOpen
        && css`
          background-color: #fff;
          color: #242424;
        `}
`
