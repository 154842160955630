import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'

import { InPlamTotalTemplate } from '@components/templates'
import qs from 'qs'

interface InPlamTotalPageProps {
  articleStore: ArticleStore;
  authStore: AuthStore;
  inplamStore: InplamStore;
  match: Any;
  history: Any;
  location: Any;
}

const InPlamTotalPage = ({
  match,
  location,
  history,
  authStore,
  articleStore,
  inplamStore,
}: InPlamTotalPageProps) => {
  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    // 문자열 맨 앞의 ?를 생력
  })

  /* store */
  const fetchTotalArticleList = articleStore.fetchTotalArticleList || (() => {})

  const currentUser = authStore.currentUser || null
  const artistInfo = articleStore.artistInfo || null
  const totalArticleList = articleStore.totalArticleList || null
  const totalArticleListCount = articleStore.totalArticleListCount || null

  const totalInplamList = inplamStore.totalInplamList || null
  const fetchTotalInplamList = inplamStore.fetchTotalInplamList || (() => {})
  /* store end */

  const [offset, setOffset] = useState(query.offset ? Number(query.offset) : 0)
  const [sortBy, setSortBy] = useState(
    query.sortBy ? query.sortBy : 'createdAt',
  )
  const [activeTab, setActiveTab] = useState('total')
  const [searchWord, setSearchWord] = useState(
    query.searchWord ? query.searchWord : null,
  )

  const handlePagination = _offset => {
    if (!searchWord) {
      history.push(
        `/fanspot/total?offset=${_offset}&sortBy=${sortBy}&filterBy=${activeTab}`,
      )
    }
    else {
      history.push(
        `/fanspot/total?offset=${_offset}&sortBy=${sortBy}&filterBy=${activeTab}&searchWord=${searchWord}`,
      )
    }
  }

  const handleChangeSortBy = _sortBy => {
    if (!searchWord) {
      history.push(
        `/fanspot/total?offset=${offset}&sortBy=${_sortBy}&filterBy=${activeTab}`,
      )
    }
    else {
      history.push(
        `/fanspot/total?offset=${offset}&sortBy=${_sortBy}&filterBy=${activeTab}&searchWord=${searchWord}`,
      )
    }
  }

  const handleChangeSearchWord = _searchWord => {
    if (!_searchWord) {
      history.push(
        `/fanspot/total?offset=${offset}&sortBy=${sortBy}&filterBy=${activeTab}`,
      )
    }
    else {
      history.push(
        `/fanspot/total?offset=${offset}&sortBy=${sortBy}&searchWord=${_searchWord}&filterBy=${activeTab}`,
      )
    }
  }

  useEffect(() => {
    if (query.offset) setOffset(Number(query.offset))
    if (query.sortBy) setSortBy(query.sortBy)
    setSearchWord(query.searchWord)
  }, [query.offset, query.sortBy, query.searchWord])

  useEffect(() => {
    const options = {
      limit: 10,
      sortBy,
      offset,
      isNotice: activeTab === 'notice',
    }

    if (searchWord) {
      options.__searchWord = searchWord
    }

    if (activeTab === 'favorite') {
      options.isInplamFavorite = 1
    }

    fetchTotalArticleList(options)
  }, [offset, sortBy, activeTab, searchWord])

  useEffect(() => {
    if (!currentUser) {
      window.localStorage.setItem('plam_current_page', location.pathname)
    }
  }, [currentUser])
  useEffect(() => {
    const fetchTotalList = async () => {
      const result = await fetchTotalInplamList({
        offset: 0,
        limit: 20,
        isFavorite: 1,
      })

      if (!!result?.length < 20) {
        const result2 = await fetchTotalInplamList({
          offset: 0,
          limit: 20,
          isFavorite: 0,
        })
      }
    }

    fetchTotalList()
  }, [])

  useEffect(() => {
    if (query?.filterBy) {
      setActiveTab(query.filterBy)
    }
    else if (
      totalInplamList?.findIndex(val => !!val?.isInplamFavorite) > -1
    ) {
      setActiveTab('favorite')
    }
    else {
      setActiveTab('total')
    }
  }, [totalInplamList])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <InPlamTotalTemplate
      uniqueName={match.params.uniqueName}
      artistInfo={artistInfo}
      totalArticleList={totalArticleList}
      totalArticleListCount={totalArticleListCount}
      offset={offset}
      handlePagination={handlePagination}
      sortBy={sortBy}
      handleChangeSortBy={handleChangeSortBy}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      totalInplamList={totalInplamList}
      currentUser={currentUser}
      searchWord={searchWord}
      handleChangeSearchWord={handleChangeSearchWord}
    />
  )
}

export default inject(
  'authStore',
  'articleStore',
  'inplamStore',
)(observer(InPlamTotalPage))
