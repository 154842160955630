import React from 'react'
import { Helmet } from 'react-helmet'
import Routes from './pages/Routes'
import 'mobx-react-lite/batchingForReactDom'

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyALWN6cNGve5SO5LzsaXPkb41nMagpJP0U',
  authDomain: 'plamin-app.firebaseapp.com',
  projectId: 'plamin-app',
  storageBucket: 'plamin-app.appspot.com',
  messagingSenderId: '313274659037',
  appId: '1:313274659037:web:2437f0ed07353a318deb34',
  measurementId: 'G-9WPNSTDQNQ',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)

function App() {
  // window.onload = function () {
  //   setTimeout(function () {
  //     window.scrollTo(0, 0)
  //   }, 1)
  // }

  return (
    // 모바일 웹 좌우 스크롤 방지
    <div className="App" id="App" style={{ overflow: 'hidden' }}>
      <Helmet>
        <title>플램 | PLAM.IN</title>
        <meta
          name="description"
          content="음악 팬을 위한, 팬에 의한 공간, 플램 PLAM.in ~ 새로운 음악과 뮤지션을 발견하는 즐거움과 애정하는 아티스트를 덕질하는 즐거움 ~ 새로운 음악과 뮤지션을 발견하는 '리워드 음감회' ~ 팬을 위한 아티스트 커뮤니티 '팬스팟'"
        />
      </Helmet>
      <Routes />
    </div>
  )
}

export default App
