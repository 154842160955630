import { Flex, H4, H6 } from '@components/atoms'
import { InplamArtistListItem } from '@components/molecules'
import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { move_btn_bottom_img, move_down_gray_img } from '@images/'
import { colors } from '@colors'

const TotalInplamArtistList = ({
  list,
  setOffset,
  sortBy,
  handleChangeSortBy,
  more,
  fetchTotalList,
  offset,
  isFavorite,
}) => {
  const history = useHistory()

  const dataList =
    list?.findIndex(val => val?.isInplamFavorite) > -1
      ? list?.filter(val => val?.isInplamFavorite)
      : list

  const [isExpanded, setIsExpanded] = useState(false)
  const [isOverflowing, setIsOverflowing] = useState(false)
  const [isFinish, setIsFinish] = useState(false)
  const listRef = useRef(null)

  useEffect(() => {
    if (
      list?.findIndex(val => val?.isInplamFavorite) > -1
      && list?.findIndex(val => !val?.isInplamFavorite) > -1
    ) {
      setIsFinish(true)
    }
  }, [list])

  // 감지 로직
  useEffect(() => {
    const observer = new ResizeObserver(() => {
      if (listRef.current) {
        const listHeight = listRef.current.offsetHeight
        const singleRowHeight = 32 + 8 // 아이템 높이 + 간격
        const maxHeight = singleRowHeight * 4 - 8 // 4줄 기준 높이
        setIsOverflowing(listHeight >= maxHeight) // 4줄 이상인지 판단
      }
    })

    if (listRef.current) {
      observer.observe(listRef.current)
    }

    return () => {
      if (listRef.current) {
        observer.unobserve(listRef.current)
      }
    }
  }, [dataList])

  return (
    <Flex type="column" style={{ marginTop: 16 }}>
      <Flex justify="space-between" align="center" style={{ marginBottom: 16 }}>
        <H4 type="Bold">
          {
list?.findIndex(val => val?.isInplamFavorite) > -1
  ? '나의 팬스팟'
  : '팬스팟 목록'
          }
        </H4>
        {/* <Select
          value={sortBy}
          onChange={e => handleChangeSortBy(e.target.value)}
        >
          <option value="newest">실시간</option>
          <option value="korean">이름순</option>
          <option value="popularity">인기순</option>
        </Select> */}
      </Flex>
      <List ref={listRef} isExpanded={isExpanded}>
        {
dataList?.map(item => (
  <InplamArtistListItem
    item={item}
    key={item._id}
    onClick={
      () => {
        if (item?.isInplamOpen) {
          history.push(
            `/fanspot/${item.uniqueName}?offset=0&sortBy=createdAt`,
          )
        }
        else {
          history.push(`/artist/${item.uniqueName}`)
        }
      }
    }
  />
))
        }
      </List>
      {
        ((!isExpanded && isOverflowing)
        || (!isFinish && isExpanded && more)) && (
          <Flex
            justify="center"
            align="center"
            style={
              {
                width: '100%',
                margin: '8px 0 0 0',
                height: 40,
                cursor: 'pointer',
                border: 'solid 1px #d4d4d4',
              }
            }
            onClick={
              async () => {
                if (!isExpanded && isOverflowing) {
                  setIsExpanded(true)
                }
                else {
                  await fetchTotalList({
                    __offset: offset,
                    __limit: 20,
                    __isFavorite: isFavorite,
                  })
                  setOffset(prevState => prevState + 1)
                }
              }
            }
          >
            <H6>더보기</H6>
            <img
              src={move_btn_bottom_img}
              style={{ marginLeft: 4, width: 16, height: 16 }}
              alt="more_img"
            />
          </Flex>
        )
      }
    </Flex>
  )
}

export default TotalInplamArtistList

const List = styled(Flex)`
  position: relative;
  margin-left: -8px;
  flex-wrap: wrap;
  div + div {
    margin: 0 4px 8px 4px;
  }
  div:first-child {
    margin: 0px 4px;
  }

  overflow: hidden;

  ${({ isExpanded }) =>
    isExpanded
      ? css`
          max-height: auto; /* 더보기 상태일 때 높이 제한 해제 */
        `
      : css`
          max-height: calc(
            32px * 4 + 8px * 3
          ); /* 4줄 제한 (아이템 높이 + 간격) */
        `}
`

const Select = styled.select`
  font-size: 12px;
  font-family: NotoSansCJKkr-Medium;
  -webkit-appearance: none;
  border: none;
  color: ${colors.brown_grey};

  background-color: transparent;
  background-image: url(${move_down_gray_img});
  background-position: right;
  background-size: 12px;
  background-repeat: no-repeat;
  display: block;

  width: 50px;
  background-position-x: 100%;

  /* option:not(:first-of-type) {
    font-size: 1.5em;
  } */
`
