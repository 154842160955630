import React, { useEffect, useRef, useState } from 'react'
import { BottomSheet } from 'react-spring-bottom-sheet'
import './FanspotModal.css'
import { useStore } from '@utils/hooks'
import { Observer } from 'mobx-react-lite'
import { Caption1, Flex, H6, Input } from '@components/atoms'
import styled from 'styled-components'
import { Image } from '@components/molecules'

import {
  default_profile_img,
  star_fill_24,
  search_outline_24px,
} from '@images/'
import { STORAGE_URL } from '@consts'

const FanspotCategoryBottomModal = ({
  isModalOpen = false,
  setIsCategoryModalOpen = () => {},
  fetchCategory = () => {},
  isHome = false,
  history,
  fanSpotCategoryList,
  setFanSpotCategoryList,
  selectedCategory,
  setSelectedCategory,
}) => {
  const scrollerRef = useRef(null)

  const handleBottomModalOpen = () => {
    setIsCategoryModalOpen(true)
  }
  const handleBottomModalClose = () => {
    setIsCategoryModalOpen(false)
  }

  const fetchCategoryList = async () => {
    const result = await fetchCategory()

    if (!result) return

    setFanSpotCategoryList(result)
  }

  return (
    <BottomSheet
      style={{ maxWidth: '480px' }}
      open={isModalOpen}
      onDismiss={
        () => {
          handleBottomModalClose()
        }
      }
      snapPoints={
        ({ minHeight }) => {
          return minHeight
        }
      }
      onSpringStart={
        async event => {
          if (event.type === 'OPEN') {
          // the bottom sheet gently waits
          // and now we can proceed
            fetchCategoryList({ __offset: 0 })
          }
        }
      }
    >
      <Flex type={'column'} style={{ padding: '8px 20px' }}>
        <Observer>
          {
            () => {
              return (
                <Flex
                  ref={scrollerRef}
                  type={'column'}
                  style={
                    {
                      gap: '16px',
                      overflow: 'scroll',
                    }
                  }
                >
                  {
fanSpotCategoryList?.map(val => (
  <FanSpotCategoryListItem
    key={val?._id}
    item={val}
    onClickItem={
      item => {
        setSelectedCategory(item)
        handleBottomModalClose()
      }
    }
  />
))
                  }
                </Flex>
              )
            }
          }
        </Observer>
      </Flex>
    </BottomSheet>
  )
}

export default FanspotCategoryBottomModal

const SearchInplamInputContainer = styled(Flex)`
  width: 100%;
  height: 40px;

  border: none !important;
  background-color: #f4f4f4;

  box-sizing: border-box;
`
const SearchInplamInput = styled(Input)`
  flex: 1;
  font-size: 12px;
  border: none !important;
  background-color: transparent;
  padding: 11.5px 8px 11.5px 12px;
`

const FanSpotCategoryListItem = ({ item, onClickItem = () => {} }) => {
  return (
    <Flex
      onClick={
        () => {
          onClickItem(item)
        }
      }
      align={'center'}
      style={{ height: '32px', cursor: 'pointer' }}
    >
      <H6>{item?.name || ''}</H6>
    </Flex>
  )
}
