import React from 'react'
import './Popup.css'
import styled, { keyframes } from 'styled-components'
import { Typography } from '../../atoms'

const boxFade = keyframes`
  0% { opacity: 0;}
  20% { opacity: 1; }
  50% { opacity: 1; }
  80% { opacity: 1; }
  100% { opacity: 0; }
`

const AnimatioWrapper = styled.div`
  animation-name: ${boxFade};
  animation-duration: 2s;
  animation-iteration-count: no;
  animation-fill-mode: forwards;
  z-index: 100;
`

const PopupInner = styled.div`
  position: absolute;
  width: calc(100% - 40px);
  height: 50px;
  box-shadow: 0 0 6px 0 rgba(102, 188, 106, 0.78);
  left: 20px;
  top: 70vh;
  background: #66bc6a;
  z-index: 1000;
  border-radius: 6px;
  color: white;

  display: flex;
  align-items: center;
  justify-content: center;

  animation-name: ${boxFade};
  animation-duration: 2s;
  animation-iteration-count: no;
  animation-fill-mode: forwards;
`

const LinkCopiedPopup = () => {
  return (
    // <AnimatioWrapper>
    <PopupInner>
      <Typography type="Medium" size="12px">
        주소가 클립보드에 복사되었습니다.
      </Typography>
    </PopupInner>
    // </AnimatioWrapper>
  )
}

export default LinkCopiedPopup
