import { Flex, H4, Caption1, Caption2, H6 } from '@components/atoms'
import {
  recycle_img_gray,
  move_btn_img,
  default_profile_img,
  thumbup_fill_24_off,
  thumbup_fill_24_on,
  comments_fill_24,
  pen_fill_24,
  star_fill_24,
} from '@images/'
import React from 'react'
import { colors } from '@colors/'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { useStore } from '@utils/hooks'
import { Observer } from 'mobx-react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { Image } from '@components/molecules'
import { STORAGE_URL } from '@consts'
import moment from 'moment'
import { convertToPricingComma } from '@utils/format'

const HomeInPlamList = ({
  fetchPopularArticleList,
  popularArticleList,
  handleArticleLike,
  setIsFanSpotModalOpen,
  setIsShowLoginPopup,
}) => {
  const history = useHistory()
  const { handlerObj, authStore } = useStore()

  const dateFromString = date => {
    const diffDay = moment().diff(moment(date), 'days')
    const diffTime = moment().diff(moment(date), 'minute')

    if (diffDay > 7) {
      if (moment(date).isSame(moment(), 'years')) {
        return moment(date).format('M월 D일')
      }

      return moment(date).format('YYYY.MM.DD')
    }
    if (diffDay === 0) {
      if (diffTime < 60) {
        return `${diffTime}분 전`
      }
      return `${Math.floor(diffTime / 60)}시간 전`
    }
    return `${diffDay}일 전`
  }

  return (
    <Flex
      type="column"
      style={
        {
          padding: '0px 20px',
          marginTop: '16px',
          marginBottom: '24px',
        }
      }
    >
      <Flex justify="space-between" align="center">
        <Flex
          align="center"
          style={{ cursor: 'pointer', height: '29px' }}
          onClick={
            () =>
              history.push('/fanspot/total?offset=0&sortBy=createdAt')
          }
        >
          <H4 type="Bold" align="left">
            팬스팟
          </H4>
          <img
            src={move_btn_img}
            style={{ width: '20px', height: '20px' }}
            alt="move_icon"
          />
        </Flex>
        <Flex align="center" style={{ cursor: 'pointer' }}>
          <img
            src={pen_fill_24}
            alt="refresh_icon"
            style={{ marginRight: 1.5, width: 20, height: 20 }}
          />
          <Caption1
            type="Regular"
            color={colors.brownish_grey}
            onClick={
              () => {
                if (!(authStore.currentUser && authStore.currentUser._id)) {
                  setIsShowLoginPopup(true)
                  return
                }

                setIsFanSpotModalOpen(true)
              }
            }
          >
            글쓰기
          </Caption1>
        </Flex>
      </Flex>
      <InPlamList>
        <Observer>
          {
            () => {
              if (handlerObj?.['fetchPopularArticleList']?.isLoading) {
                return Array(3)
                  .fill(1)
                  .map((item, index) => (
                    <InPlamListItem key={`skel-${index}`}>
                      <Flex
                        style={
                          {
                            marginBottom: '9px',
                            justifyContent: 'space-between',
                          }
                        }
                      >
                        <Flex>
                          {
                            <ArtistText>
                              <Skeleton width={60} height={13} />
                            </ArtistText>
                          }
                        </Flex>
                        <Caption2 color={'#949494'} type={'Regular'}>
                          <Skeleton width={50} height={14} />
                        </Caption2>
                      </Flex>
                      <Flex align={'center'}>
                        <Image
                          src={default_profile_img}
                          size={['24px', '24px']}
                          style={
                            {
                              marginRight: '8px',
                              borderRadius: '50%',
                              border: `solid 0.5px ${colors.inActive}`,
                            }
                          }
                        />
                        {
                          <WriterText color={'#949494'}>
                            <Skeleton width={50} height={12} />
                          </WriterText>
                        }
                      </Flex>
                      <Flex type={'column'} style={{ marginTop: '2px' }}>
                        <TitleText>
                          <Skeleton width={'60%'} height={16} />
                        </TitleText>
                        <BodyText>
                          <Skeleton width={'100%'} height={16} />
                        </BodyText>
                      </Flex>
                      <Flex style={{ gap: '12px', marginTop: '8px' }}>
                        <Flex align={'center'}>
                          <Image
                            src={thumbup_fill_24_off}
                            size={['20px', '20px']}
                            style={
                              {
                                marginRight: '2px',
                                cursor: 'pointer',
                              }
                            }
                          />
                          <Caption1 color={'#646464'}>
                            <Skeleton width={15} height={13} />
                          </Caption1>
                        </Flex>
                        <Flex>
                          <Image
                            src={comments_fill_24}
                            size={['20px', '20px']}
                            style={
                              {
                                marginRight: '2px',
                              }
                            }
                          />
                          <Caption1 color={'#646464'}>
                            <Skeleton width={15} height={13} />
                          </Caption1>
                        </Flex>
                      </Flex>
                    </InPlamListItem>
                  ))
              }

              if (popularArticleList && popularArticleList.length) {
                return popularArticleList?.slice(0, 3).map(item => (
                  <InPlamListItem
                    key={item._id}
                    onClick={
                      () => {
                        if (item.isNotice) {
                          history.push(
                            `/fanspot/total/${item._id}?offset=0&sortBy=createdAt`,
                          )
                        }
                        else {
                          history.push(
                            `/fanspot/${item.uniqueName}/${item._id}?offset=0&sortBy=createdAt`,
                          )
                        }
                      }
                    }
                  >
                    <Flex
                      style={
                        {
                          marginBottom: '9px',
                          justifyContent: 'space-between',
                        }
                      }
                    >
                      <Flex align={'center'}>
                        {
                          item.isNotice ? (
                            <ArtistText
                              style={
                                {
                                  color: '#ea4653',
                                  border: 'solid 1px #ea4653',
                                  cursor: 'pointer',
                                  borderRadius: 2,
                                  fontFamily: 'NotoSansCJKkr-Medium',
                                  padding: '2px 0',
                                }
                              }
                              // onClick={
                              //   () =>
                              //     history.push(
                              //       `/fanspot/total/${item._id}?offset=0&sortBy=createdAt`,
                              //     )
                              // }
                            >
                          공지사항
                            </ArtistText>
                          ) : (
                            <ArtistText>{item.artistName}</ArtistText>
                          )
                        }
                        {
item?.artistInfo?.isInplamFavorite && !item.isNotice && (
                            <Image src={star_fill_24} size={['12px', '12px']} />
                          )
                        }
                      </Flex>
                      <Caption2 color={'#949494'} type={'Regular'}>
                        {dateFromString(item?.createdAt)}
                      </Caption2>
                    </Flex>
                    <Flex align={'center'}>
                      <Image
                        src={
                        item?.writerInfo?.profileImage64Path
                          ? `${STORAGE_URL}${item?.writerInfo?.profileImage64Path}`
                          : default_profile_img
                        }
                        size={['24px', '24px']}
                        style={
                          {
                            marginRight: '8px',
                            borderRadius: '50%',
                            border: `solid 0.5px ${colors.inActive}`,
                          }
                        }
                      />
                      {
                        <WriterText color={'#949494'}>
                          {item?.isAnonymous ? '익명' : item?.writerInfo?.name}
                        </WriterText>
                      }
                    </Flex>
                    <Flex type={'column'} style={{ marginTop: '2px' }}>
                      <TitleText>{item.title}</TitleText>
                      <BodyText>{item.text}</BodyText>
                    </Flex>
                    <Flex style={{ gap: '12px', marginTop: '8px' }}>
                      <Flex align={'center'}>
                        <Image
                          src={
                          item?.isLike
                            ? thumbup_fill_24_on
                            : thumbup_fill_24_off
                          }
                          size={['20px', '20px']}
                          style={
                            {
                              marginRight: '2px',
                              cursor: 'pointer',
                            }
                          }
                          onClick={
                            () => {
                              handleArticleLike(item)
                            }
                          }
                        />
                        <Caption1 color={'#646464'}>
                          {
item?.likeCount > 100000
  ? '10만+'
  : convertToPricingComma(item?.likeCount)
                          }
                        </Caption1>
                      </Flex>
                      <Flex>
                        <Image
                          src={comments_fill_24}
                          size={['20px', '20px']}
                          style={
                            {
                              marginRight: '2px',
                            }
                          }
                        />
                        <Caption1 color={'#646464'}>
                          {
item?.likeCount > 100000
  ? '10만+'
  : convertToPricingComma(item?.commentCount)
                          }
                        </Caption1>
                      </Flex>
                    </Flex>
                  </InPlamListItem>
                ))
              }
              return <Flex>인기 게시글이 없습니다</Flex>
            }
          }
        </Observer>
      </InPlamList>
    </Flex>
  )
}

export default HomeInPlamList

const InPlamList = styled(Flex)`
  flex-direction: column;
  height: fit-content;
  /* max-height: 170px; */
  margin: 16px 0px;
  gap: 12px;
`

const InPlamListItem = styled(Flex)`
  cursor: pointer;
  width: 100%;
  /* height: 34px; */
  box-sizing: border-box;
  flex-direction: column;
  padding: 12px 14px;
  background: #fff;
  border-radius: 12px;
`

const ArtistText = styled(Caption2)`
  font-family: NotoSansCJKkr-Bold;
  /* text-align: left; */
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
`
const WriterText = styled(Caption1)`
  font-family: NotoSansCJKkr-Bold;
  /* text-align: left; */
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
`

const TitleText = styled(H6)`
  width: 100%;
  font-family: NotoSansCJKkr-Medium;
  text-align: left;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
`
const BodyText = styled(H6)`
  width: 100%;
  font-family: NotoSansCJKkr-Regular;
  text-align: left;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
`
