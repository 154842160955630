import { action, observable } from 'mobx'
import { Store } from '.'
import { InplamModel } from './models'
import { Network } from './networks'

export default class InplamStore {
  @observable totalInplamList
  @observable searchInplamList

  constructor(store: Store, network: Network) {
    this.store = store
    this.network = network

    this.init()
  }

  @action.bound
  init() {
    this.totalInplamList = []
    this.searchInplamList = []
  }

  @action.bound
  fetchTotalInplamList = async (params: {
    isFavorite?: 0 | 1,
    sortBy?: 'newest' | 'korean' | 'popularity',
    offset?: Number,
    sort?: Number,
    limit?: Number,
    noNeedStore?: boolean,
  }) => {
    return this.store.useLoading(async () => {
      const res = await this.network.inplamNetwork.getInplam(params)
      if (!res) return

      if (!params?.noNeedStore) {
        if (params.isFavorite === 1 && params.offset === 0) {
          this.totalInplamList = res.inplamList
            .filter(elem => !!elem)
            .map(elem => new InplamModel(this.store, elem))
        }
        else if (params.offset > 0 || params.isFavorite === 0) {
          this.totalInplamList = this.totalInplamList.concat(
            (this.totalInplamList = res.inplamList
              .filter(elem => !!elem)
              .map(elem => new InplamModel(this.store, elem))),
          )
        }
      }

      return res.inplamList
    }, 'fetchTotalInplamList')
  }

  @action.bound
  fetchSearchInplamList = async (params: {
    sortBy?: 'newest' | 'korean' | 'popularity',
    offset?: Number,
    sort?: Number,
    limit?: Number,
    name?: string,
  }) => {
    return this.store.useLoading(async () => {
      const res = await this.network.inplamNetwork.getInplam(params)
      if (!res) return

      if (params.offset === 0) {
        this.searchInplamList = res.inplamList
          .filter(elem => !!elem)
          .map(elem => new InplamModel(this.store, elem))
      }
      else if (params.offset > 0) {
        this.searchInplamList = this.searchInplamList.concat(
          (this.searchInplamList = res.inplamList
            .filter(elem => !!elem)
            .map(elem => new InplamModel(this.store, elem))),
        )
      }

      return res.inplamList
    })
  }

  @action.bound
  addFavoritesInplam = async artistId => {
    if (
      this.store.authStore.currentUser
      && !this.store.authStore.currentUser._id
    )
      return
    return this.store.useLoading(async () => {
      const res = await this.network.inplamNetwork.postInplamFavorites({
        artistId,
      })
      if (!res) return
      this.store.articleStore.inplamFavoritesInfo = res.results
      this.store.authStore.currentUser.inplamFavorites.push(res.results)
      return !!res
    })
  }
  @action.bound
  removeFavoritesInplam = async favoriteId => {
    if (
      this.store.authStore.currentUser
      && !this.store.authStore.currentUser._id
    )
      return
    return this.store.useLoading(async () => {
      const res = await this.network.inplamNetwork.deleteInplamFavorites(
        favoriteId,
      )
      if (!res) return

      this.store.articleStore.inplamFavoritesInfo = null

      const findIndex = this.store.authStore.currentUser.inplamFavorites.findIndex(
        item => item['_id'] === favoriteId,
      )

      this.store.authStore.currentUser.inplamFavorites.splice(findIndex, 1)
      return !!res
    })
  }

  @action.bound
  accountOpenRequests = artistId => {
    return this.store.useLoading(async () => {
      const res = await this.network.inplamNetwork.postOpenRequests(artistId)
      if (!res) return

      return res.requestInfo
    })
  }

  @action.bound
  updateOpenRequests = (requestsId, artistId, userPhoneNumber) => {
    return this.store.useLoading(async () => {
      const res = await this.network.inplamNetwork.putOpenRequests(requestsId, {
        artistId,
        userPhoneNumber,
      })
      if (!res) return

      if (res.error) {
        if (res.error.code === 'E101') {
          return 'DUPLICATED_PHONE_NUMBER'
        }
      }

      return res.requestInfo
    })
  }

  @action.bound
  fetchArtistFanSpotPointInfo = async (params: { uniqueName: string }) => {
    return this.store.useLoading(async () => {
      const res = await this.network.inplamNetwork.getArtistFanSpotPoint(params)
      if (!res) return

      return res
    })
  }

  @action.bound
  fetchArtistFanSpotPointRank = async (params: { uniqueName: string }) => {
    return this.store.useLoading(async () => {
      const res = await this.network.inplamNetwork.getArtistFanSpotPointRank(
        params,
      )
      if (!res) return

      return res
    })
  }

  @action.bound
  fetchInplamByUniqueName = async (params: { uniqueName: string }) => {
    return this.store.useLoading(async () => {
      const res = await this.network.inplamNetwork.getInplamByUniqueName(params)
      if (!res) return

      return res?.inplam
    })
  }
}
