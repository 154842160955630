import { Caption1, Flex, H5, H6 } from '@components/atoms'
import React, { useEffect, useRef, useState } from 'react'
import { colors } from '@colors/'
import styled from 'styled-components'
import {
  InPlamListItem,
  SegmentedControl,
  TabControl,
} from '@components/molecules'
import {
  move_down_gray_img,
  last_page_img,
  no_last_page_img,
  first_page_img,
  no_first_page_img,
} from '@images/'
import Pagination from 'react-js-pagination'
import { edit_img_black, pen_fill_24 } from '@images/index'

const InPlamList = ({
  style,
  offset,
  handlePagination,
  artistArticleList,
  artistArticleListCount,
  sortBy,
  handleChangeSortBy,
  categoryList,
  category,
  handleChangeCategory,
  artistInfo,
  handleMoveDetail,
  history,
}) => {
  const [categoryData, setCategoryData] = useState([
    {
      label: 'ALL',
      value: 'all',
      ref: useRef(),
    },
  ])
  const [categoryIndex, setCategoryIndex] = useState(0)

  const categoryRefs = useRef(
    Array(categoryList?.length + 2).fill(React.createRef()),
  )

  useEffect(() => {
    const data = [
      {
        label: 'ALL',
        value: 'all',
      },
      ...categoryList?.map(val => {
        return {
          label: val?.name,
          value: val?._id,
        }
      }),
      {
        label: '아티스트',
        value: 'artist',
      },
    ]

    data?.forEach((val, index) => {
      val.ref = React.createRef()
    })

    setCategoryData(data)

    setCategoryIndex(
      data?.findIndex(val => val.value === category) === -1
        ? 0
        : data?.findIndex(val => val.value === category),
    )
  }, [categoryList])

  useEffect(() => {
    return () => {
      // Clean up references on unmount
      categoryRefs.current = {}
    }
  }, [])

  const sortByData = [
    {
      label: '최신순',
      value: 'createdAt',
      ref: useRef(),
    },
    {
      label: '인기순',
      value: 'popularity',
      ref: useRef(),
    },
    {
      label: '조회순',
      value: 'viewCount',
      ref: useRef(),
    },
  ]

  return (
    <Flex type="column" style={style && style}>
      {/* <Flex type={'column'} style={{}}> */}
      <Flex
        type={'column'}
        style={
          {
            marginBottom: '12px',
            position: 'sticky',
            top: '52px',
            zIndex: 50,
          }
        }
      >
        <TabControl
          name="category"
          callback={
            val => {
              handleChangeCategory(val)
            }
          }
          defaultIndex={categoryIndex}
          segments={categoryData}
        />
      </Flex>
      <Flex type={'column'} style={{ padding: '0 20px' }}>
        <SegmentedControl
          name="sortBy"
          callback={
            val => {
              handleChangeSortBy(val)
            }
          }
          defaultIndex={sortByData?.findIndex(val => val.value === sortBy)}
          segments={sortByData}
        />
      </Flex>
      {/* </Flex> */}
      <Flex type={'column'} style={{ padding: '0 20px' }}>
        <Header>
          <H5 type="Bold">{`전체 ${artistArticleListCount || 0}`}</H5>
          <Flex>
            <Flex
              align="center"
              justify="center"
              style={{ cursor: 'pointer' }}
              onClick={
                () =>
                  history.push(
                    `/fanspot/add/${artistInfo?.uniqueName}/${artistInfo
                    && artistInfo._id}`,
                  )
              }
            >
              <img
                src={pen_fill_24}
                alt="edit_icon"
                style={{ width: 20, height: 20 }}
              />
              <Caption1>글쓰기</Caption1>
            </Flex>
          </Flex>
        </Header>
        {/* <Br /> */}
        <Flex type={'column'} style={{ gap: '24px' }}>
          {
            artistArticleList
            && artistArticleList.map(item => (
              <InPlamListItem
                item={item}
                key={item._id}
                artistInfo={artistInfo}
                onClick={handleMoveDetail}
                category={category}
              />
            ))
          }
        </Flex>
      </Flex>
      <Pagination
        activePage={offset + 1}
        itemsCountPerPage={10}
        totalItemsCount={artistArticleListCount || 0}
        pageRangeDisplayed={5}
        onChange={
          page => {
            handlePagination(page - 1)
          }
        }
        innerClass="pagination-ul"
        activeClass="current-page"
        itemClass="pagination-li"
        // hideFirstLastPages
        hideNavigation
        // prevPageText={false}
        // nextPageText={false}
        firstPageText={
          <PrevNextPage
            src={offset + 1 !== 1 ? first_page_img : no_first_page_img}
          />
        }
        lastPageText={
          <PrevNextPage
            src={
              offset + 1 !== Math.ceil(artistArticleListCount / 10)
                ? last_page_img
                : no_last_page_img
            }
          />
        }
      />
    </Flex>
  )
}

export default InPlamList

const Header = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  height: 44px;
  margin-bottom: 8px;
  margin-top: 10px;
`

const Br = styled(Flex)`
  margin-left: -20px;
  width: calc(100% + 40px);
  border-bottom: solid 1px ${colors.whitef4};
`

const Select = styled.select`
  font-size: 12px;
  font-family: NotoSansCJKkr-Medium;
  -webkit-appearance: none;
  border: none;
  color: ${colors.brown_grey};

  background-color: transparent;
  background-image: url(${move_down_gray_img});
  background-position: right;
  background-position-x: 38px;
  background-size: 12px;
  background-repeat: no-repeat;
  width: 50px;
  display: block;

  /* option:not(:first-of-type) {
    font-size: 1.5em;
  } */
`

const PrevNextPage = styled.img`
  width: 12px;
  height: 12px;
  cursor: pointer;
`
