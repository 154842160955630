import { computed, observable } from 'mobx'
import { STORAGE_URL } from '@consts'

export default class ArticleModel {
  /* Database fields */
  @observable _id

  @observable likeCount
  @observable reportCount

  @observable imageOriginalPathList
  @observable image480PathList
  @observable image640PathList
  @observable image850PathList
  @observable imageFilenameList

  @observable viewCount
  @observable popularity
  @observable commentCount
  @observable isHidden
  @observable title
  @observable text
  @observable writerId
  @observable artistId
  @observable parendId
  @observable writerInfo
  @observable artistInfo
  @observable isLike
  @observable isMine
  @observable isNotice
  @observable isAnonymous
  @observable isOfficial
  @observable createdAt
  @observable updatedAt
  @observable deletedAt

  @observable notificationCount
  @observable articleCategoryId
  @observable articleCategoryName

  /* Database fields end */
  @observable artistName
  @observable uniqueName
  @observable artistUserId

  @computed get isViewHidden() {
    return !this.isMine && this.isHidden
  }

  @computed get image480PathUriList() {
    if (this.image480PathList && this.image480PathList.length > 0) {
      const tmpList = this.image480PathList.map(elem => `${STORAGE_URL}${elem}`)
      return tmpList
    }
    return []
  }

  @computed get imageOriginalPathUriList() {
    if (this.imageOriginalPathList && this.imageOriginalPathList.length > 0) {
      const tmpList = this.imageOriginalPathList.map(
        elem => `${STORAGE_URL}${elem}`,
      )
      return tmpList
    }
    return []
  }

  constructor(stores, props) {
    if (props) {
      this._id = props._id

      this.likeCount = props.likeCount
      this.reportCount = props.reportCount

      this.imageOriginalPathList = props.imageOriginalPathList
      this.image480PathList = props.image480PathList
      this.image640PathList = props.image640PathList
      this.image850PathList = props.image850PathList
      this.imageFilenameList = props.imageFilenameList

      this.viewCount = props.viewCount
      this.popularity = props.popularity
      this.commentCount = props.commentCount
      this.isHidden = props.isHidden
      this.title = props.title
      this.text = props.text
      this.writerId = props.writerId
      this.artistId = props.artistId
      this.parendId = props.parendId
      this.writerInfo = props.writerInfo
      this.artistInfo = props.artistInfo
      this.isLike = props.isLike
      this.isMine = props.isMine
      this.isNotice = props.isNotice
      this.isAnonymous = props.isAnonymous
      this.isOfficial = props.isOfficial
      this.createdAt = props.createdAt
      this.updatedAt = props.updatedAt
      this.deletedAt = props.deletedAt

      this.notificationCount = props.notificationCount

      this.artistName = props.artistName
      this.uniqueName = props.uniqueName
      this.artistUserId = props.artistUserId

      this.articleCategoryId = props.articleCategoryId
      this.articleCategoryName = props.articleCategoryName
    }
  }
}
